import React from 'react';
import Marketo from '../marketo';
import CatoImg from '../cato-image';
import CatoLink from '../cato-link';
import {EMAIL_FORM_ID} from "../../common/consts";
import './style.scss';

const BlogSidebar = (props) => {
    const banners = props.banners
        ?
        <div className="sidebar-bottom">
            {props.banners.map((item, i) => (
                <div key={i} className={`section-side ${i === 0 ? 'one' : ''}`}>
                    <a href={item.sidebarLink} target="_blank" rel="noopener noreferrer">
                        <CatoImg img={item.sidebarImage}/>
                    </a>
                </div>))}
        </div>
        :
        <div className="sidebar-bottom">
            <div className="section-side one">
                <a href="https://blog.feedspot.com/networking_blogs/" target="_blank" rel="noopener noreferrer">
                    <img src="https://go.catonetworks.com/rs/245-RJK-441/images/Top-100-Networking-Blogs-Cato-Networks.png" alt="Get the eBook"/>
                </a>
            </div>
            <div className="section-side">
                <a href="https://go.catonetworks.com/Guide-to-WAN-Architecture-and-Design-Executive-Summary.html" target="_blank" rel="noopener noreferrer">
                    <img src="https://go.catonetworks.com/rs/245-RJK-441/images/2018-Guide-to-WAN-Architecture-and-Design-blog-side-Executive-Summary.png" alt="Get it now"/>
                </a>
            </div>
        </div>


    const topics = props.relatedTopics && props.relatedTopics.links ? <div className="topics-list-wrapper">
        <h3>Related Topics</h3>
        <ul>
            {
                props.relatedTopics.links.map((item, i) => <li key={`blog-topic-link-${i}`}><CatoLink path={item.path}>{item.displayName}</CatoLink></li>)
            }
        </ul>
    </div> : null;

    return (
        <aside className="right-sidebar">
            <div className="row sidebar-top">
                <div className="row subscribe-social">
                    <h4>Follow Us</h4>
                    <ul className="subscribe-social-lists hide-bullets-style">
                        <li className="sub-twt"><a href="https://twitter.com/catonetworks" target="_blank" rel="noopener noreferrer" className="sidebar-twitter-icon"><i aria-label="Twitter Link Icon"/></a></li>
                        <li className="sub-fb"><a href="https://www.linkedin.com/company/cato-networks" target="_blank" rel="noopener noreferrer" className="sidebar-lkin-icon"><i aria-label="LinkedIn Link Label"/></a></li>
                        <li className="sub-fb"><a href="https://www.facebook.com/CatoNetworks/" target="_blank" rel="noopener noreferrer" className="sidebar-fb-icon"><i aria-label="Facebook Link Label"/></a></li>
                    </ul>
                </div>
            </div>
            {banners}
            {topics}
        </aside>
    )
};

export default BlogSidebar;