import React, {Component} from 'react';
import Layout from '../components/layout';
import BlogSidebar from '../components/blog-sidebar';
import {Link, graphql, navigate} from "gatsby";
import CatoLink from '../components/cato-link';
import CatoImg from '../components/cato-image';
import Pagination from "react-js-pagination";
import {htmlDecode, mapStickyBanner, mapGartner} from '../common/utils';
import {IS_MOBILE, LANG_PATH_PREFIX} from "../common/consts";
import '../assets/styles/pages/_blog.scss';
import '../assets/styles/responsive/pages/_blog-mobile.scss';

class PostLobby extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false
        };

        this.type = props.pageContext.type;
        this.lang = props.pageContext.lang;
    }

    componentDidMount() {
        if(IS_MOBILE) {
            this.setState({isMobile: true})
        }
    }

    handlePageChange = (page) => {
        if(page === 1)
            return navigate(`/${this.type}`);
        navigate(`/${this.type}/page/${page}`);
    };

    pagination = () => {
        const {numPages, currentPage, limit} = this.props.pageContext;
        return (
            <div>
                <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={limit}
                    totalItemsCount={numPages * limit}
                    pageRangeDisplayed={5}
                    onChange={page => this.handlePageChange(page)}
                />
            </div>
        )
    };

    genBreadcrumbs = () => {
        const {wpPage} = this.props.data;
        return (
            <div className="bread-crumbs">
                <Link to="/">Home</Link>
                <span> > </span>
                <span>{wpPage.title}</span>
            </div>
        )
    };

    generateArticleList = () => {
        // const {nodes} = this.props.pageContext.blog ? this.props.data.allWpPost : this.props.data.allWpNewsItem;
        const {nodes} = this.props.data.allWpPost;

        return nodes.map((item, i) => {

            const path = `${LANG_PATH_PREFIX[item.language.code]}${this.type}/${item.slug}/`

            return (
                <div className={`article${this.props.pageContext.news ? ' news': ''}`} key={`article-${i}`}>
                    {!this.props.pageContext.news && <CatoLink path={path} className="img-wrapper">
                            {/*{this.props.pageContext.news && item.node.acf && item.node.acf.logo && <img src={item.node.acf.logo.source_url} alt="Company Logo"/>}*/}
                            {item.featuredImage && item.featuredImage.node && <CatoImg img={this.state.isMobile ? item.featuredImageMobile.node : item.featuredImage.node}/>}
                        </CatoLink>}
                    <div className="text">
                        {item.title &&
                        <h2 className="entry-title">
                            <CatoLink path={path} setHTML={item.title}/>
                        </h2>
                        }
                        {item.author && item.author.name &&
                        <div className="author-and-date">
                            <span>{item.author.name}</span><span>·</span><span>{item.date}</span>
                        </div>}

                        {/*{item.node.acf && item.node.acf.excerpt && <div className="excerpt">{item.node.acf.excerpt}</div>}*/}
                        <CatoLink path={path} className="more-posts">Read More...</CatoLink>
                    </div>
                </div>
            )
        })
    };

    render() {
        const {data} = this.props;
        const {wpPage} = data;
        const {postLobbySidebars, relatedTopicsLinksParent} = wpPage;

        const breadcrumbs = this.genBreadcrumbs(wpPage);
        const articles = this.generateArticleList();
        const sideBanners = postLobbySidebars.sidebarBanners ? postLobbySidebars.sidebarBanners : null;
        const relatedTopics = relatedTopicsLinksParent.relatedTopicsLinks ? {
            title: relatedTopicsLinksParent.relatedTopicsHeadline,
            links: relatedTopicsLinksParent.relatedTopicsLinks.map(item => ({displayName: item.title, path: item.link}))
        } : null;

        const stickyBanner = mapStickyBanner(wpPage)

        const gartner = mapGartner(wpPage);

        return (
            <Layout
                className="post-lobby"
                lang={this.lang}
                whiteMenu={true}
                seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
                seoPath={this.props.location.pathname}
                gartner={gartner}
                yoastData={wpPage.seo}
                translations={this.props.pageContext.translations}
                wpid={wpPage.databaseId}
                {...{stickyBanner}}>
                <div className="content-wrapper">
                    {breadcrumbs}
                    <article className="article-wrapper">
                        <div className="articles">
                            {articles}
                            {this.pagination()}
                        </div>
                    </article>
                    <BlogSidebar
                        location={this.props.location}
                        banners={sideBanners}
                        relatedTopics={relatedTopics}
                    />
                </div>
            </Layout>
        )
    }
}

export default PostLobby;

export const pageQuery = graphql`
    query postLobbyPageQuery($id: String!, $limit: Int!, $skip: Int!, $blog: Boolean!, $exclude: [Int], $lang: WpLanguageCodeEnum!) {
        wpPage(id: { eq: $id }) {
            ...YoastData
            title
            content
            slug
            id
            databaseId
            date(formatString: "MMMM DD, YYYY")
            path: uri
            pageSeoTitle {
                pageSeoTitle
            }
            postLobbySidebars {
                sidebarBanners {
                    sidebarImage {
                        altText
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 288, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                            publicURL
                        }
                    }
                    sidebarLink
                }
            }
            relatedTopicsLinksParent {
                relatedTopicsHeadline
                relatedTopicsLinks {
                    title
                    link
                }
            }
            ...YoastData
            ...StickyBanner
            ...GartnerStrip
        }
        allWpPost (
            sort: {fields: date, order: DESC}
            filter: {status: {eq: "publish"}, databaseId: {nin: $exclude}, language: {code: {eq: $lang}}}
            limit: $limit
            skip: $skip
        ) @include (if: $blog) {
                nodes {
                    title
                    id
                    slug
                    path: uri
                    language {
                        code
                    }
                    date(formatString: "MMMM D, YYYY")
                    author {
                        node {
                            name
                        }
                    }
                    featuredImage {
                        node {
                            altText
                            localFile {
                                childImageSharp {
                                    fixed(width: 199){
                                        ...GatsbyImageSharpFixed_withWebp_noBase64
                                    }
                                }
                                publicURL
                            }
                            sourceUrl
                        }
                    }
                    featuredImageMobile: featuredImage {
                        node {
                            altText
                            localFile {
                                childImageSharp {
                                    fixed(width: 260){
                                        ...GatsbyImageSharpFixed_withWebp_noBase64
                                    }
                                }
                                publicURL
                            }
                            sourceUrl
                        }
                    }
                }
        }
}`